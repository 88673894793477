import { Region } from './region.models';

export class SetRegions {
  static readonly type = '[RegionState] SetRegions';
  constructor(public readonly regions: Region[]) {}
}

export class SetActiveRegion {
  static readonly type = '[RegionState] SetActiveRegion';
  constructor(public readonly region: Region) {}
}
