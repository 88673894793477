import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetLoading, ToggleDarkTheme } from './layout.actions';

export interface LayoutStateModel {
  loading: boolean;
  darkTheme: boolean;
}

@State<LayoutStateModel>({
  name: 'layoutState',
  defaults: {
    loading: false,
    darkTheme: true,
  },
})
@Injectable()
export class LayoutState {
  @Selector()
  static loading(state: LayoutStateModel) {
    return state.loading;
  }

  @Selector()
  static darkTheme(state: LayoutStateModel) {
    return state.darkTheme;
  }

  @Action(SetLoading)
  setLoading({ patchState }: StateContext<LayoutStateModel>, { loading }: SetLoading) {
    patchState({
      loading: loading,
    });
  }

  @Action(ToggleDarkTheme) toggleDarkTheme(ctx: StateContext<LayoutStateModel>) {
    const darkTheme = ctx.getState().darkTheme;
    ctx.patchState({ darkTheme: !darkTheme });
  }
}
