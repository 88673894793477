import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetActiveRegion, SetRegions } from './region.actions';
import { Region } from './region.models';

export interface RegionStateModel {
  regions?: Region[];
  activeRegion?: Region;
}

@State<RegionStateModel>({
  name: 'regionState',
  defaults: {},
})
@Injectable()
export class RegionState {
  @Selector() static regions(state: RegionStateModel) {
    return state.regions;
  }

  @Selector() static activeRegion(state: RegionStateModel) {
    return state.activeRegion;
  }
  @Selector() static activeRegionCode(state: RegionStateModel) {
    return state.activeRegion?.regionCode;
  }

  @Action(SetRegions) setRegions(ctx: StateContext<RegionStateModel>, action: SetRegions) {
    ctx.patchState({ regions: action.regions });
  }

  @Action(SetActiveRegion) setActiveRegion(
    ctx: StateContext<RegionStateModel>,
    action: SetActiveRegion,
  ) {
    ctx.patchState({ activeRegion: action.region });
  }
}
